import { createSlice } from '@reduxjs/toolkit'



const initialState = {
    sidebarShow: false,
    sidebarUnfoldable:false,
    asideShow: false,
    theme: 'default',
    userid:0,
    username:"",
    userdepot:0,
    depotname:"",
    userrole:0,
    userolename:"",
    usertoken:"",usr:"",
    dashveh:"",
    dashsch:"",
    dashdri:"",
    dashcond:"",
    dashdepot:"",
    url:"",
    vehiclelist:[], 
    selectedvehicleID:"",
    selectedvehicle:"",
    selectedvehiclejobid:"",
    selectedvehiclejob:"",
    selectedvehicleidtl:"",
    selectedvehicletl:"",
    selectedvehicleIDfleet:"",
    selectedvehiclefleet:"",
    fleetloggingmenu:"",
    fleetloggingsubmenu:"",
    selectedvehicleindex:-1,
    selectedvehiclejobindex:-1,
    selectedvehicletlindex:0,
    selectedpartjobindex:-1,
    selectedpartjob:"",
    selectedpartjobID:0,
    userpermission:[],
    exportdata:[],
    employeeurl:"",
  }

//export const apiurl ="http://localhost:3000/api";
//export const apiurl ="https://ksrtcfleetoptics.com/api";
export const apiurl ="https://ksrtc.capcp.in/apiv1";

export const encykey='sdjfdjie3jrjfjodkfnopffg'

export const timelineupdatevehicle=(vehicleid,description,userid,module,shortdescription,submodule,odo)=>{
    var myHeaderstim = new Headers();
myHeaderstim.append("Content-Type", "application/json");

let timobj = {
		"timelineid" : 0,
		"vehicleid" : vehicleid,
		"description" : description,
		"updatedby" :userid,
		"module" : module,
		"shortdescription" : shortdescription,
		"submodule" : submodule,
    "odo":odo
}
console.log(timobj)
var requestOption = {
	method: 'POST',
	body: JSON.stringify(timobj),
	headers: myHeaderstim,
	redirect: 'follow'
  };
  fetch(apiurl+"/historytimeline", requestOption)
  .then(async response =>
	{
	  var res = await response.json();
	  if(res.affectedRows>0)
	  {
		
		
	  }
	}
  ).catch(error => console.log('error', error));
}
export const timelineupdatepart=(partid,description,userid,module,shortdescription,submodule,odo)=>{
    var myHeaderstim = new Headers();
myHeaderstim.append("Content-Type", "application/json");

let timobj = {
		"timelineid" : 0,
		"partid" : partid,
		
		"description" : description,
		"updatedby" :userid,
		"module" : module,
		"shortdescription" : shortdescription,
		"submodule" : submodule,
        "odo":odo
}

var requestOption = {
	method: 'POST',
	body: JSON.stringify(timobj),
	headers: myHeaderstim,
	redirect: 'follow'
  };
  fetch(apiurl+"/historytimeline", requestOption)
  .then(async response =>
	{
	  var res = await response.json();
	  if(res.affectedRows>0)
	  {
		
		
	  }
	}
  ).catch(error => console.log('error', error));
}
export const timelineupdateitem=(itemid,description,userid,module,shortdescription,submodule)=>{
    var myHeaderstim = new Headers();
myHeaderstim.append("Content-Type", "application/json");

let timobj = {
		"timelineid" : 0,
		"itemid" : itemid,
		
		"description" : description,
		"updatedby" :userid,
		"module" : module,
		"shortdescription" : shortdescription,
		"submodule" : submodule
}

var requestOption = {
	method: 'POST',
	body: JSON.stringify(timobj),
	headers: myHeaderstim,
	redirect: 'follow'
  };
  fetch(apiurl+"/historytimeline", requestOption)
  .then(async response =>
	{
	  var res = await response.json();
	  if(res.affectedRows>0)
	  {
		
		
	  }
	}
  ).catch(error => console.log('error', error));
}

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        set: (state, action) => {
            let { data, type } = { ...action.payload }
            state[type] = data
            if(type==="reset")
            {
                state= initialState;
            }
        },
        setlocallist: (state, action) => {
          let { data, type } = { ...action.payload }
          state[type] = data
         
      },
        setlist: (state, action) => {
            let { data, type } = { ...action.payload }
            // let arr =[];
            // data.forEach(element => {
            //     arr.push(element)
                   
            // });
            state[type]= data 
            
        },
        updateAppState: (state, action) => {
            let { data, type } = { ...action.payload }
            state[type] = data
            if (type === "menu" && data !== "report") {
                state["subMenu"] = "Individual" // menu "Report" sub menu to default Individual
            }else if(type==="reset")
            {
                state= initialState;
            }
        },
        updateDefaultVariant: (state, action) => {
            if (state.variantsToCompare.length > 1) {
                let otherVariantsToCompare = state.variantsToCompare.slice(1)
                state.variantsToCompare = [action.payload, ...otherVariantsToCompare]
            } else {
                state.variantsToCompare = [action.payload]
            }
        },
        addOtherVariant: (state, action) => {
            state.variantsToCompare = [...state.variantsToCompare, action.payload]
        },
        updateAppStateBulk: (state, action) => {
            state = action.payload
        },
        
    }
})


// Action creators are generated for each case reducer function
export const { set,setlist,updateAppState, updateDefaultVariant, addOtherVariant, updateAppStateBulk } = appSlice.actions

export default appSlice.reducer