import React, { Component, Suspense } from 'react'
import { HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import { AuthProvider } from 'react-auth-kit'
import RequireAuth from '../src/views/login/RequireAuth'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/login/Login'))
const Register = React.lazy(() => import('./views/register/Register'))
// const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
// const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

// Email App
//const EmailApp = React.lazy(() => import('./views/apps/email/EmailApp'))
const isLogged = () => localStorage.getItem("logged");
const isLoggedIn =()=>{
  if(isLogged=='true')
  {
    return true;
  }else
  {
    return false;
  }
}
class App extends Component {
  render() {
    return (
      // <AuthProvider authType = {'cookie'}
      // authName={'_auth'}
      // cookieDomain={window.location.hostname}
      // cookieSecure={window.location.protocol === "https:"}>
      <HashRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route exact path="/login" name="Login Page" element={<Login />} />
            <Route exact path="/register" name="Register Page" element={<Register />} />
            {/* <Route exact path="/404" name="Page 404" element={<Page404 />} />
            <Route exact path="/500" name="Page 500" element={<Page500 />} />
            <Route path="/apps/email/*" name="Email App" element={<EmailApp />} /> */}
            {/* <Route path="*" name="Home" element={<DefaultLayout />} /> */}
            {/* <Route
          path="*"  name="Home" element={<DefaultLayout/>} />  */}
          
        
          </Routes>
          {isLoggedIn ?
          <DefaultLayout />:''}
          
        </Suspense>
      </HashRouter>
      // </AuthProvider>
    )
  }
}

export default App
