// import { createStore } from 'redux'


// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState)
// export default store
import { configureStore,getDefaultMiddleware } from '@reduxjs/toolkit'
import appReducer from "./appslice"
import thunkMiddleware from 'redux-thunk'
import { setupListeners } from '@reduxjs/toolkit/query'


export const store = configureStore({
    reducer: {
        app : appReducer,
       
    },
    
    middleware: [thunkMiddleware, ...getDefaultMiddleware()],
    
    
    // middleware:(getDefaultMiddleware) =>  getDefaultMiddleware({
    //     thunk:true
    // })
})

setupListeners(store.dispatch)
